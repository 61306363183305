.btn {
    background-color: var(--primary-text-color);
}

.btn:hover {
    background-color: var(--primary-dark-text-color);
}

.cancel_btn {
    background-color: white;
    color: var(--primary-text-color);
    border: 1px solid var(--light-grey);
    font-weight: 400;
}

.cancel_btn:hover {
    border: 1px solid var(--primary-text-color);
    background-color: white;
}

.cross_button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 1.2rem 0.4rem 1.2rem;
}

.header span {
    font-size: 1.2rem;
    font-weight: 600;
}

.message {
    margin: 0 1.2rem 2rem 1.2rem;
    font-size: 1.1rem;
    font-weight: 400;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1.2rem;
}

.buttons > button:not(:last-child) {
    margin-right: 1rem;
}