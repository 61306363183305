.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}