.users-table {
    margin-top: 20px;
    width: 100%;
  
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th {
      background-color: #f2f2f2;
      border: 1px solid #ccc;
      padding: 10px;
    }
  
    td {
      border: 1px solid #ccc;
      padding: 10px;
    }
  
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  
    tr:hover {
      background-color: #f9f9f9;
    }
  
    .actions {
      display: flex;
      justify-content: space-around;
    }
  }

  .container {
    margin: 2rem;
  }