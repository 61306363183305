.dashboard {
    text-align: center;
  }
  
  .data-points {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .data-point {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    width: 150px;
  }
  
  .data-point h2 {
    margin-bottom: 10px;
  }
  
  .data-point p {
    font-size: 24px;
    font-weight: bold;
  }
  
  .data-point span {
    font-size: 14px;
    font-weight: normal;
  }
  
  .stamps-table {
    margin-top: 20px;
    width: 100%;
  
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th {
      background-color: #f2f2f2;
      border: 1px solid #ccc;
      padding: 10px;
    }
  
    td {
      border: 1px solid #ccc;
      padding: 10px;
    }
  
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  
    tr:hover {
      background-color: #f9f9f9;
    }
  
    .actions {
      display: flex;
      justify-content: space-around;
    }
  }