.root {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
}

.label {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
}

.icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }