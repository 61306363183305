.container {
    text-align: center;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    background-color: white;
    font-family: Arial, sans-serif;
}

.logo {
    width: 150px;
    margin-bottom: 40px;
}

.download-buttons a {
    display: block;
    margin: 10px 0;
}

.download-buttons img {
    width: 200px;
    transition: transform 0.3s;
}

.download-buttons img:hover {
    transform: scale(1.05);
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1000;
}