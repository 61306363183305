@keyframes loader-animation {
    0% {
      transform: rotateY(0deg);
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
  
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
  
    100% {
      transform: rotateY(3600deg);
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    display: inline-block;
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: var(--loader-size);
    animation: loader-animation 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }